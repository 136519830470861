import React, { useState } from "react";
import ExcelReader from "./ExcelReader";
import Calendar from "./Calendar";

function CalenderAfterSetHolidays() {
  const [holidays, setHolidays] = useState([]);

  // 这里的 handleExcelData 函数由 ExcelReader 调用，将 Excel 数据传入
  const handleExcelData = (data) => {
    setHolidays(data);
  };

  return (
    <div>
      {/* ExcelReader 读取完数据后，通过回调将假日信息传给本组件 */}
      <ExcelReader onDataLoaded={handleExcelData} />
      
      {/* 再把假日信息传给 Calendar，用于标记不允许的日期 */}
      <Calendar holidays={holidays} />
    </div>
  );
}

export default CalenderAfterSetHolidays;
