import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import {AccessTokenProvider} from './components/Appointment/AccessTokenContext'; // 引入 Provider


import HomePage from './components/HomePage/Index';
import Appointment from './components/Appointment/Index';
import ContactUs from './components/ContactUs/Index';



function App() {
    return (
        <AccessTokenProvider>
            <Router future={{v7_relativeSplatPath: true}}>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/appointment" element={<Appointment/>}/>
                    <Route path="/contactus" element={<ContactUs/>}/>                    <Route path="/contactus" element={<ContactUs/>}/>
                </Routes>
            </Router>
        </AccessTokenProvider>
    )
        ;
}

export default App;
