import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import AppointmentDetail from "./AppointmentDetail";
import "./Calendar.css"; // 导入 CSS 文件

const Calendar = ({ holidays = [] }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const [events, setEvents] = useState([]);

    // 仅允许星期四（4）和星期五（5）
    const allowedDays = [4, 5];

    // useEffect(() => {
    //     console.log("holidays changed =>", holidays);
    //   }, [holidays]);
      
    const handleDateSelect = (selectInfo) => {
        const date = new Date(selectInfo.startStr);
        const dayOfWeek = date.getDay();

        // 如果选择的日期不是允许的日期，或者是节假日
        if (!allowedDays.includes(dayOfWeek) || isHoliday(date)) {
            alert("Please choose available days!");
            return;
        }

        setSelectedDate(selectInfo.startStr); // 设置选定的日期
        setShowDetail(true); // 显示预约详情弹窗
    };

    const handleConfirm = (newEvent) => {
        // 将确认的事件添加到 FullCalendar 的状态中
        setEvents((prevEvents) => [
            ...prevEvents,
            {
                title: `Appointment: ${newEvent.name}`,
                start: newEvent.start,
                end: newEvent.end,
            },
        ]);

        setShowDetail(false); // 关闭详情弹窗
    };

    function isSameDay(d1, d2) {
        return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        );
    }

    const isHoliday = (calendarDate) => {
        // console.log("Checking date:", calendarDate.toDateString());
        const found = holidays.some((h) => {
            // h.date 如果本身就是 Date 对象，直接比较
            // console.log(
            //     "Comparing with holiday:",
            //     h.date,
            //     " => ",
            //     h.date.toDateString()
            // );
            return isSameDay(h.date, calendarDate);
        });

        // console.log("Is holiday?", found ? "Yes" : "No", "\n");
        return found;
    };


    // 获取本地日期字符串，格式为 'YYYY-MM-DD'
    const getTodayString = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // 月份从0开始
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    return (
        <div className="row">
            <div className="calendar-container col-11 col-lg-7">
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin, bootstrap5Plugin]}
                    initialView="dayGridMonth"
                    selectable={true}
                    select={handleDateSelect}
                    events={events} // 在日历上渲染事件
                    selectLongPressDelay={0}
                    validRange={{
                        start: getTodayString(), // 使用本地日期，包含今天
                    }}
                    timeZone="local" // 确保使用本地时区
                    dayCellClassNames={({ date }) => {
                        const day = date.getDay();

                        if (isHoliday(date) || !allowedDays.includes(day)) {
                            return "disallowed-day";
                        }
                        return "allowed-day";
                    }}
                />
                <AppointmentDetail
                    show={showDetail} // 是否显示弹窗
                    onHide={() => setShowDetail(false)} // 关闭弹窗
                    selectedDate={selectedDate} // 传递选定的日期
                    onConfirm={handleConfirm} // 确认事件的回调函数
                />
            </div>
        </div>
    );
};

export default Calendar;
