import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";

function ExcelReader({ onDataLoaded }) {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchExcelFile = async () => {
      try {
        const response = await fetch("/dates.xlsx");
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // ① 用 raw: true 保留 Excel 原始数值
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });

        // ② 解析日期序列号或字符串
        const filtered = jsonData.map((row) => {
          let dateValue = row.Date;
          let realDate;

          if (typeof dateValue === "number") {
            // 如果是数字，说明是 Excel 日期序列号
            const parsed = XLSX.SSF.parse_date_code(dateValue);
            // 注意：JS 的月份要 -1
            realDate = new Date(parsed.y, parsed.m - 1, parsed.d);
          } else {
            // 否则可能是字符串：直接 new Date()
            realDate = new Date(dateValue);
          }

          return {
            date: realDate,  // 存一个真正的 Date 对象
            name: row.Name
          };
        });

        setFilteredData(filtered);
        // ③ 把处理好的数组传回父组件
        onDataLoaded(filtered);
      } catch (error) {
        console.error("Error reading the Excel file:", error);
      }
    };

    fetchExcelFile();
  }, [onDataLoaded]);

//   return (
//     <div>
//       {filteredData.map((item, index) => (
//         <div key={index}>
//           <strong>Date:</strong> {item.date.toDateString()} 
//           {/* 例如显示成 Mon Jan 27 2025 */}
//         </div>
//       ))}
//     </div>
//   );
}

export default ExcelReader;
