import React from 'react';
import Banner from "../Banner/Banner";
import Footer from "../Footer/Footer";
import GuideForBooking from "./GuideForBooking";
import TokenRequest from "./TokenRequest";
// import Calendar from "./Calendar";
import CalenderAfterSetHolidays from "./CalenderAfterSetHolidays"

function Index(props) {
    return (
        <div>
            <Banner/>
            <GuideForBooking/>
            <TokenRequest/>
            <CalenderAfterSetHolidays />
            <Footer/>
        </div>
    );
}


export default Index;